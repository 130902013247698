import React from 'react';
import styled from 'styled-components';
import { Box } from '@latitude/box';
import { Heading4 } from '@latitude/heading';
import {
  ALIGN,
  COLOR,
  BREAKPOINT,
  FONT_FAMILY,
  FONT_WEIGHT,
  MARGIN
} from '@latitude/core/utils/constants';
import Icon from '@latitude/svg-inline/images/external-link.svg';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import HarveyNorman from './images/merchant_large_harvey_norman.png';
import Domayne from './images/merchant_large_domayne.png';
import JoyceMayne from './images/merchant_large_joyce-mayne.png';


const Partners = () => (
  <Box.Section backgroundColor={COLOR.GREY6}>
    <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
      Visit one of our partners
    </Heading4>
    <Items>
      {[
        {
          id: 'Harvey Norman',
          href: 'http://harveynorman.com.au',
          imagesrc: HarveyNorman
        },
        {
          id: 'Domayne',
          href: 'http://domayne.com.au',
          imagesrc: Domayne
        },
        {
          id: 'Joyce Mayne',
          href: 'http://joycemayne.com.au',
          imagesrc: JoyceMayne
        }
      ].map(item => (
        <Item href={item.href} key={item.id} id={item.id}>
          <img src={item.imagesrc} alt={item.id} />
        </Item>
      ))}
      <Item
        href="/interest-free/find-a-store/"
        id="View other partners"
      >
        <Text>View other partners</Text>
      </Item>
    </Items>
  </Box.Section>
);

export default Partners;

const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Item = props => {
  const ItemLink = styled(Link)`
    margin: ${MARGIN.M24} calc(${MARGIN.M24} / 2) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 240px;
    height: 48px;
    background: ${COLOR.WHITE};
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    }

    p {
      font-family: ${FONT_FAMILY.TITLE};
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    }

    img {
      height: 20px;
    }
  `;

  const IconExtrernal = styled(Icon)`
    margin-left: 10px;

    path {
      fill: ${COLOR.BLUE_DEEP};
    }
  `;

  return (
    <ItemLink
      noStyle
      href={props.href || '#'}
      trackEventData={{
        location: 'Visit one of our partners',
        label: props.id
      }}
    >
      {props.children}
      <IconExtrernal />
    </ItemLink>
  );
};
