import React from 'react';
import HowToApplyComponent, {
  InnerContainer,
  ContentBox
} from '@latitude/how-to-apply';
import { ListNumbered } from '@latitude/list-numbered';
import { Bold } from '@latitude/text';
import { Box } from '@latitude/box';
import { BREAKPOINT, COLOR, MARGIN } from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';
import { List, ListItem } from '@latitude/list';
import { Vertical } from '@latitude/spacing';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';

const HowToApply = () => (
  <div id="how-to-apply">
    <div id="eligibility">
      <Box
        backgroundColor={COLOR.GREY6}
        css={`
          padding: 16px 0;
          @media (min-width: ${BREAKPOINT.LG}) {
            padding: 48px 0;
          }
        `}
      >
        <HowToApplyComponent
          css={`
            ${InnerContainer} {
              background-color: #e8f4ff;
            }
            ${ContentBox} {
              border-color: #b1dbff;
            }
          `}
          box1={
            <Vertical spacing={MARGIN.M16}>
              <Heading4 color={COLOR.BLACK} isResponsive={false}>
                How to apply
              </Heading4>
              <div
                css={`
                  ol li {
                    align-items: flex-start;
                  }
                `}
              >
                <ListNumbered
                  data={[
                    '<div><strong>Apply online</strong><br />You can check your eligibility and apply in 10 minutes.</div>',
                    '<div><strong>Wait a minute</strong><br />60 seconds is all it takes for us to respond to your application.</div>',
                    '<div><strong>Ready, set, shop</strong><br />Simply activate your card when it arrives to start shopping.</div>'
                  ]}
                  separator="none"
                  counterBgColor={COLOR.BLUE_DEEP}
                />
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  css="width: 100%; margin: 32px 0 auto;"
                  href="https://cards.latitudefinancial.com/go"
                  data-trackid="apply-now"
                  trackEventData={{
                    label: 'Apply now',
                    location: 'How to apply'
                  }}
                >
                  Apply now
                </Link>
              </div>
            </Vertical>
          }
          box2={
            <Vertical spacing={MARGIN.M16}>
              <Heading4 color={COLOR.BLACK} isResponsive={false}>
                Eligibility
              </Heading4>
              <Vertical spacing={MARGIN.M8}>
                <Bold>You must:</Bold>
                <List>
                  <ListItem>Be 18 years or over</ListItem>
                  <ListItem>Be a permanent Australian resident</ListItem>
                </List>
              </Vertical>
              <Vertical spacing={MARGIN.M8}>
                <Bold>Able to supply:</Bold>
                <List>
                  <ListItem>
                    A copy of your driver&apos;s licence, passport or proof of
                    age
                  </ListItem>
                  <ListItem>Details of your income, assets and debts</ListItem>
                  <ListItem>Employer&apos;s name and contact details</ListItem>
                </List>
              </Vertical>
            </Vertical>
          }
        />
      </Box>
    </div>
  </div>
);

export default HowToApply;
