import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked } from 'marked';
import Slider from '@latitude/slider';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import './carouselHeader.css';

const REFRESH_INTERVAL_MILLIS = 5 * 1000; // Time to display each header (milliseconds)

export function contentfulItemLayout(item, key) {
  const primaryButtons = item.buttons?.filter(
    button => button?.theme === 'primary'
  );
  const primaryInverseButtons = item.buttons?.filter(
    button => button?.theme === 'primaryInverse'
  );
  const primaryButtonUrls = primaryButtons
    ? primaryButtons.map(button => button.url)
    : undefined;
  const primaryButtonLabels = primaryButtons
    ? primaryButtons.map(button => button.text)
    : undefined;
  const primaryInverseButtonUrls = primaryInverseButtons
    ? primaryInverseButtons.map(button => button.url)
    : undefined;
  const primaryInverseButtonLabels = primaryInverseButtons
    ? primaryInverseButtons.map(button => button.text)
    : undefined;

  return (
    <div key={key}>
      <div className="pageHeaderItem">
        <div className="pageHeaderItemText">
          {item.title && ReactHTMLParser(marked(item.title))}
          <div className="header-text">
            {item.text && ReactHTMLParser(marked(item.text))}
          </div>
          <div className="header-terms">
            {item?.termsconds && ReactHTMLParser(marked(item.termsconds))}
          </div>
          <div className="header-buttons">
            {primaryButtonUrls?.map((url, index) => (
              <Link
                key={index}
                className="lfs-find-out-more"
                button={BUTTON_STYLE.SECONDARY_INVERSE}
                target="_blank"
                rel="noopener noreferrer"
                href={url}
              >
                {primaryButtonLabels[index]}
              </Link>
            ))}
            {primaryInverseButtonUrls?.map((url, index) => (
              <Link
                key={index}
                button={BUTTON_STYLE.PRIMARY}
                target="_blank"
                rel="noopener noreferrer"
                href={url}
                trackEventData={{
                  location: 'hero'
                }}
              >
                {primaryInverseButtonLabels[index]}
              </Link>
            ))}
          </div>
        </div>
        <div className="pageHeaderItemImage">
          <img src={item.headerImage?.file?.url} alt={item.name} />
        </div>
      </div>
    </div>
  );
}

export function itemLayout(item, key) {
  return (
    <div key={key}>
      <div className="pageHeaderItem">
        <div className="pageHeaderItemText">
          <h1>{ReactHTMLParser(item.title)}</h1>
          <div className="header-text">{ReactHTMLParser(item.text)}</div>
          {/* <div className="header-terms">{ReactHTMLParser(item.termsconds)}</div> */}
          <div className="header-buttons">
            {item.urlFindOutMore && (
              <Link
                className="lfs-find-out-more"
                button={BUTTON_STYLE.SECONDARY_INVERSE}
                target="_blank"
                rel="noopener noreferrer"
                href={item.urlFindOutMore}
              >
                {item.labelFindOutMore
                  ? item.labelFindOutMore
                  : 'Find out more'}
              </Link>
            )}
            <Link
              button={BUTTON_STYLE.PRIMARY}
              target="_blank"
              rel="noopener noreferrer"
              trackEventData={{
                location: 'hero'
              }}
              href={
                item.urlApplyNow
                  ? item.urlApplyNow
                  : 'https://cards.latitudefinancial.com/gem'
              }
            >
              Apply now
            </Link>
          </div>
          <div className="header-terms">{ReactHTMLParser(item.termsconds)}</div>
        </div>
        <div className="pageHeaderItemImage">
          <img src={item.headerImage} alt={ReactHTMLParser(item.title)} />
        </div>
      </div>
    </div>
  );
}

function CarouselHeader(props) {
  return (
    <div className="pageHeader">
      {Array.isArray(props.HEADER_ITEMS) === false ? (
        <div className="pageHeader">
          {props.CONTENTFUL
            ? contentfulItemLayout(props.HEADER_ITEMS, 0)
            : itemLayout(props.HEADER_ITEMS, 0)}
        </div>
      ) : (
        <Slider
          className="lfs-carousel-pageheader"
          slidesToShow={1}
          slidesToScroll={1}
          infinite={true}
          autoplay={true}
          autoplaySpeed={props.TIMER || REFRESH_INTERVAL_MILLIS}
          pauseOnHover={true}
        >
          {props.HEADER_ITEMS.map(
            props.CONTENTFUL ? contentfulItemLayout : itemLayout
          )}
        </Slider>
      )}
    </div>
  );
}

export default CarouselHeader;
