import React from 'react';
import Section from '@latitude/section';
import Button from '@/components/lab-components/Button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './_offer_banner.scss';
import { BUTTON_THEME } from '@/utils/constants';
import { BLOCKS } from '@contentful/rich-text-types';

const LAYOUT_1_COL = '1 Col'

const OfferBanner = ({ 
  offerBannerCards,
  theme,
  title,
  layout,
}) => {
  const colClass = layout === LAYOUT_1_COL ? "col-12" : "col-lg-5 col-sm-12";
  const headingOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <h3 className={`offer-heading offer-heading--${theme}`}>{children}</h3>
      )
    }
  }

  return (
    <Section className="offer-banner">
      {title && <h3 class="offer-title" style={{ textAlign: layout === LAYOUT_1_COL ? "left" : "center" }}>{title}</h3>}
      <div class="row offer">
        {offerBannerCards.map((offerCard, index) => (
          <div className={`offer-card offer-card--${theme} ${colClass}`} key={index}>
            {offerCard?.ribbon &&
              <div className={`offer-ribbon offer-ribbon--${theme}`}>
                <span className='white-star'>&#9733;</span>{offerCard?.ribbon}
              </div>
            }
            <div className="offer-text">
              {offerCard?.heading && 
                documentToReactComponents(offerCard?.heading, headingOptions)}
              {offerCard?.description && 
                documentToReactComponents(offerCard?.description)}
              {offerCard?.primaryButton &&
                <Button
                  theme={BUTTON_THEME.APPLICATION}
                  href={offerCard?.primaryButton?.url}
                  target="_blank"
                  trackId="promo-banner"
                  trackEventData={{
                    category: 'cta',
                    action: 'quote-link',
                    location: 'gem-visa'
                  }}
                  trackProductId={offerCard.trackId || ""}
                  className="max-width-fit-content"
                  text={offerCard.primaryButton?.text}
                />
              }
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default OfferBanner;
